.sign-in1 {

    display: flex;
    margin-top: 10px;
  }

  .sign-in2 {

    margin: 70px auto;
    background-color: aqua;
    width: 500px;
    font-style: italic;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    position: relative;
    border-radius: 10px;
    height: 400px;
    display: grid;
    grid-template-columns: 1fr;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  .form {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-inputs {
    margin-bottom: 0.5rem;
    width: 80%;
}

.form-inputs p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #f00e0e;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: black;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
}
.form-input-btn {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 2px;
    background: linear-gradient( 90deg, rgb(39, 176, 255) 0%, rgb(0, 232, 236) 100%);
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
}

.form-input-btn:hover {
    cursor: pointer;
    background: linear-gradient( 90deg, rgb(39, 143, 255) 0%, rgb(12, 99, 250) 100%);
    transition: all 0.4s ease-out;
}
.form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: blue;
    width: 80%;
    text-align: center;
}
.form-input-signup {
    font-size: 0.8rem;
    margin-top: 10px;
    color: fuchsia;
    width: 80%;
    text-align: center;
}
.form-input-signup a {
    text-decoration: none;
    color: #27cdff;
    font-weight: 600;
}
