.newLoanee {
    flex: 4;
  }

  .addLoaneeForm {

    margin-top: 10px;
  }

  .addLoaneeItem {
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .addLoaneeItem > label {
    color: gray;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .addLoaneeItem > input {
    padding: 10px;
  }

  .addLoaneeItem > select {
    padding: 10px;
  }

  .addLoaneeButton {
    margin-top: 10px;
    padding: 7px 10px;
    border: none;
    border-radius: 10px;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }
  .cen{
   align-content: center;
   align-items: center;
   text-align: center;

  }